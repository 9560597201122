'use strict';
(function () {

    class SharedComponent {
        constructor(Auth, $scope, Modal, toastr, usSpinnerService, $stateParams, $state, $window, $uibModal, SharedUrl) {
            this.toastr = toastr;
            this.$state = $state;
            this.$stateParams = $stateParams;
            this.$window = $window;
            this.$uibModal = $uibModal;
            this.usSpinnerService = usSpinnerService;
            this.Modal = Modal;
            this.isDisabled = false;
            this.SharedUrl = SharedUrl;
            this.Auth = Auth;
            Auth.isLoggedInAsync((loggedIn) => {
                if (loggedIn) {
                    this.currentUser = Auth.getCurrentUser();
                    console.log(this.currentUser);
                    this.init();
                } else {
                    this.init();
                }
            });
        }

        $onInit() {
        }

        init() {
            this.loading = true;
            this.SharedUrl.get({ id: this.$stateParams.id }).$promise
                .then(response => {
                    var url = window.location.href;
                    var urlArray = url.split("/");
                    var baseUrl = urlArray[0] + "//" + urlArray[2];
                    // let responseUrl = new URL(baseUrl+'/'+response.data.url);
                    // let urlParams = new URLSearchParams(responseUrl.search.slice(1));
                    // let access_token = urlParams.get('access_token');
                    // console.log('access_token:', access_token);
                    console.log(baseUrl+'/'+response.data.url);
                    this.loading = false;
                    this.stopSpin('spinner-1');
                    if(!this.currentUser) {
                        localStorage.setItem('sharedLevel', response.data.level)
                        localStorage.setItem('org', JSON.stringify(response.data.organization));
                        this.Auth.setAccessTokenAndUserForSharedUrl(response.data.token);
                    }
                    this.$window.location.href = baseUrl+'/'+response.data.url;
                })
                .catch(err => {
                    console.log('err', err);
                    this.stopSpin('spinner-1');
                    this.loading = false;
                })
        }

        startSpin(spinner) {
            this.usSpinnerService.spin(spinner);
        }

        stopSpin(spinner) {
            this.usSpinnerService.stop(spinner);
        }
    }

    angular.module('windmanagerApp')
        .component('shared', {
            //   templateUrl: 'app/shared/shared.html',
            controller: SharedComponent,
            controllerAs: 'sc'
        });

})();
